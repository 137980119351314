import { env } from "app/config";
import { Box, Center, Image } from "@chakra-ui/react";

export const DecorationImageComponent = () => {
  return (
    <Center>
      <Box boxSize="100px">
        <Image
          alt="BinduHealth Lab Billing"
          objectFit="cover"
          src={env.hospitalLogo}
        />
      </Box>
    </Center>
  );
};
