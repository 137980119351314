import React, { useEffect, useState } from "react";
import {
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Spinner,
  Text,
  Button
} from "@chakra-ui/react";
import { FileUploadTab } from "../tabs/file-upload.tab";
import { FaCloudUploadAlt } from "react-icons/fa";
import { ActivityLogsTab } from "../tabs/activity-logs.tab";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetAllDocumentTypesQuery,
  useLazyGetPoliceCaseDetailsQuery
} from "../api";
import { Gender, DocumentType as PoliceCaseDocumentType } from "app/api/type";
import { PatientDetailComponent } from "./patient-detail.component";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";

interface FileUploadComponentProps {
  policeCaseId: string;
  subjectIndex: number;
}

export const FileUploadComponent: React.FC<FileUploadComponentProps> = (
  props: FileUploadComponentProps
) => {
  const navigate = useNavigate();

  const [getPoliceCaseDetails, getPoliceCaseDetailsResult] =
    useLazyGetPoliceCaseDetailsQuery();

  const getAllDocumentTypesResult = useGetAllDocumentTypesQuery({});

  const [documentTypes, setDocumentTypes] = useState<PoliceCaseDocumentType[]>(
    []
  );

  useEffect(() => {
    getPoliceCaseDetails({ id: props.policeCaseId });
  }, [props.policeCaseId]);

  useEffect(() => {
    if (getAllDocumentTypesResult.isSuccess) {
      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;

      if (locationCategory === "Counter") {
        setDocumentTypes([]);
      } else if (locationCategory === "Ward") {
        setDocumentTypes(
          [
            "Injury Note",
            "Treatment Sheet",
            "Diagnostic Report",
            "Transfer Note",
            "Operation Note",
            "Investigation Report",
            "Discharge Note",
            "Medical Certificate"
          ].map((name) => {
            return {
              name: name
            };
          })
        );
      } else if (locationCategory === "Medical Certification") {
        setDocumentTypes(
          [
            "Injury Note",
            "Treatment Sheet",
            "Diagnostic Report",
            "Transfer Note",
            "Operation Note",
            "Investigation Report",
            "Discharge Note",
            "Death Certificate",
            "Medical Certificate"
          ].map((name) => {
            return {
              name: name
            };
          })
        );
      }
    }
  }, [getAllDocumentTypesResult.data]);

  return getPoliceCaseDetailsResult.data ? (
    <Stack spacing="4">
      <Stack direction="row" justify={"space-between"} align={"center"}>
        <Text fontWeight={"bold"} color="gray.500" fontSize="2xl">
          Case{" "}
          {getPoliceCaseDetailsResult.data.policeCaseWithDetails.policeCase.id}
        </Text>

        <Button
          onClick={() => {
            const locationCategory = window.localStorage.getItem(
              "locationCategory"
            ) as string;
            navigate(
              `/${getDashboardName(locationCategory)}/case/${
                props.policeCaseId
              }/details`
            );
          }}
        >
          Case Details
        </Button>
      </Stack>
      <PatientDetailComponent
        patientName={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.name
        }
        patientId={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].patient.id
        }
        patientNationalIdNumber={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.nationalIdNumber
        }
        patientContactNumber={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.contactNumber
        }
        dateOfBirth={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.dateOfBirth
        }
        gender={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.gender as Gender
        }
      />
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList>
          {documentTypes.map((documentType) => {
            return <Tab>{documentType.name}</Tab>;
          })}
          {/* <Tab>Activity Log</Tab> */}
        </TabList>
        <TabPanels>
          {documentTypes.map((documentType) => {
            return (
              <TabPanel>
                <FileUploadTab
                  documentTypeName={documentType.name}
                  policeCaseId={props.policeCaseId}
                  patientId={
                    getPoliceCaseDetailsResult.data?.policeCaseWithDetails
                      .subjects[props.subjectIndex].patient.id as string
                  }
                  allowedFileTypes={{
                    "image/*": [".png", ".jpg", ".jpeg"]
                  }}
                  allowMultiple={false}
                  maxFiles={1}
                  uploadIcon={
                    <FaCloudUploadAlt size="100px" color="blue.500" />
                  }
                  instructionText="Drag files here or click to upload"
                  instructionSubtext="Drop file to upload"
                  dragAcceptText="Drag file here"
                  dragRejectText="Cannot upload this"
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Stack>
  ) : (
    <Center width="100%" height="256px">
      <Spinner />
    </Center>
  );
};
