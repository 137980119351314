import React from "react";
import { Stack } from "@chakra-ui/react";
import { FileUploadComponent } from "../components/file-upload.component";

import { useSearchParams } from "react-router-dom";

interface FileUploadPageProps {}

export const FileUploadPage: React.FC<FileUploadPageProps> = (
  props: FileUploadPageProps
) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const policeCaseId = searchParams.get("caseId");
  const subjectIndex = parseInt(
    searchParams.get("subjectIndex")
      ? (searchParams.get("subjectIndex") as string)
      : "0"
  );

  return (
    <Stack>
      <FileUploadComponent
        policeCaseId={policeCaseId as string}
        subjectIndex={subjectIndex}
      />
    </Stack>
  );
};
