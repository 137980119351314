import React from "react";
import {
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { PoliceCase, PoliceCaseWithDetails } from "app/api/type";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";

interface PoliceCaseTableComponentProps {
  policeCasesWithDetails: PoliceCaseWithDetails[];
}

export const ExistingPoliceCaseTableComponent: React.FC<
  PoliceCaseTableComponentProps
> = (props: PoliceCaseTableComponentProps) => {
  const navigate = useNavigate();

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Sl.</Th>
          <Th>Creation Date</Th>
          <Th>ID</Th>
          <Th>Patients</Th>
          {/* <Th>Action</Th> */}
        </Tr>
      </Thead>
      <Tbody>
        {props.policeCasesWithDetails.map((policeCaseWithDetail, index) => {
          return (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                {dayjs(policeCaseWithDetail.policeCase.createdAt).format(
                  "MMM DD, YYYY hh:mm A"
                )}
              </Td>
              <Td>{policeCaseWithDetail.policeCase.id}</Td>
              <Td>
                {" "}
                <Stack>
                  {" "}
                  {policeCaseWithDetail.subjects.map((subject) => {
                    return (
                      <Text>
                        {`${subject.person.name} (${
                          subject.person.contactNumber
                            ? subject.person.contactNumber
                            : "-"
                        })`}
                      </Text>
                    );
                  })}{" "}
                </Stack>
              </Td>

              {/* <Td>
                <Stack direction="row">
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${getDashboardName(locationCategory)}/case/${
                          policeCaseWithDetail.policeCase.id
                        }/details`
                      );
                    }}
                  >
                    Details
                  </Button>
                </Stack>
              </Td> */}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
