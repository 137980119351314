import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FaSearch } from "react-icons/fa";
import {
  useLazyUseSearchPoliceCasesQuery,
  useLazyGetPoliceCaseReportQuery
} from "../api";
import { PoliceCaseTableComponent } from "./police-case-table.component";
import { CreateCaseComponent } from "./create-case.component";

interface SearchCaseComponentProps {}

export const SearchCaseComponent: React.FC<SearchCaseComponentProps> = (
  props: SearchCaseComponentProps
) => {
  const [searchCase, searchCaseResult] = useLazyUseSearchPoliceCasesQuery();

  const [caseReport, caseReportResult] = useLazyGetPoliceCaseReportQuery();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearch = () => {
    searchCase({
      searchQuery
    });
    caseReport({ id: searchQuery });
  };

  useEffect(() => {
    searchCase({
      searchQuery: ""
    });
  }, []);

  return (
    <PageCardComponent>
      <Text>Search Query</Text>
      <Stack direction={{ base: "column", lg: "row" }}>
        <InputGroup size="sm">
          <Input
            value={searchQuery}
            placeholder="Search with Case ID / Patient Name / Contact / Admission Department / NID"
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
          />

          <Button
            size="sm"
            leftIcon={<FaSearch />}
            colorScheme={"blue"}
            onClick={handleSearch}
          >
            Search
          </Button>
        </InputGroup>
      </Stack>
      <Divider />
      <CreateCaseComponent />
      <Divider />
      {searchCaseResult.isSuccess && (
        <PoliceCaseTableComponent
          policeCasesWithDetails={searchCaseResult.data.policeCasesWithDetails}
        />
      )}
    </PageCardComponent>
  );
};
