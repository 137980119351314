import React from "react";
import { useGetPoliceCaseReportQuery } from "../api";
import { PrintableCaseReportPage } from "../pages/printable-case-report.page";
import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";

interface PrintableCaseReportComponentProps {
  policeCaseId: string;
}

export const PrintableCaseReportComponent: React.FC<
  PrintableCaseReportComponentProps
> = (props: PrintableCaseReportComponentProps) => {
  const data = useGetPoliceCaseReportQuery({
    id: props.policeCaseId
  });

  const Document = data.data ? (
    <PrintableCaseReportPage data={data.data} />
  ) : (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );

  const fileName = `Case-Report-${props.policeCaseId}.pdf`;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Report
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={fileName}
      />
    </>
  );
};
