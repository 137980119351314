import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  GridItem,
  Heading,
  IconButton,
  SimpleGrid,
  Spinner,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  FormControl,
  FormLabel,
  Box
} from "@chakra-ui/react";
import { useGetPoliceCaseDetailsQuery } from "../api";
import { Document, PoliceCaseWithDetails } from "app/api/type";
import {
  FaDownload,
  FaEye,
  FaFilePdf,
  FaImage,
  FaFile,
  FaRegFileImage,
  FaRegFilePdf
} from "react-icons/fa";
import { AddPatientComponent } from "./add-patient.component";
import { useNavigate } from "react-router-dom";
import { PdfViewerComponent } from "./pdf-viewer.component";
import { ImageViewerComponent } from "./image-viewer.component";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";

interface CaseDetailsComponentProps {
  policeCaseId: string;
}

export const CaseDetailsComponent: React.FC<CaseDetailsComponentProps> = (
  props: CaseDetailsComponentProps
) => {
  const navigate = useNavigate();
  const getCaseDetailsResult = useGetPoliceCaseDetailsQuery({
    id: props.policeCaseId
  });

  const [shouldShowAddPatientButton, setShouldShowAddPatientButton] =
    useState<boolean>(true);

  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [policeCaseWithDetails, setPoliceCaseWithDetails] =
    React.useState<PoliceCaseWithDetails | null>(null);

  useEffect(() => {
    if (getCaseDetailsResult.isSuccess) {
      setPoliceCaseWithDetails(getCaseDetailsResult.data.policeCaseWithDetails);
    }
  }, [getCaseDetailsResult]);

  useEffect(() => {
    if (getCaseDetailsResult.isSuccess) {
      if (
        getCaseDetailsResult.data.policeCaseWithDetails.policeCase
          .maximumPatientCount >
          getCaseDetailsResult.data.policeCaseWithDetails.subjects.length ||
        getCaseDetailsResult.data.policeCaseWithDetails.policeCase
          .maximumPatientCount === -1
      ) {
        setShouldShowAddPatientButton(true);
      } else {
        setShouldShowAddPatientButton(false);
      }
    }
  }, [getCaseDetailsResult]);

  return policeCaseWithDetails ? (
    <Stack spacing="12">
      <Stack spacing="6">
        <Heading fontWeight={"bold"} color="gray.500" fontSize="2xl">
          Case {policeCaseWithDetails.policeCase.id}
        </Heading>

        <FormControl
          borderRadius={"md"}
          p="6"
          boxShadow={"md"}
          bg="white"
          width="40%"
        >
          <FormLabel fontWeight="bold">Police Case Remarks</FormLabel>
          <Stack>
            <Text>
              {policeCaseWithDetails.policeCase.remarks
                ? policeCaseWithDetails.policeCase.remarks
                : "No remarks were provided"}
            </Text>
          </Stack>
        </FormControl>
      </Stack>

      <Stack spacing="6">
        <Stack direction={"row"} justify={"space-between"} align={"center"}>
          <Heading fontSize="xl">Patients</Heading>
          {shouldShowAddPatientButton ? (
            <AddPatientComponent policeCaseId={props.policeCaseId} />
          ) : (
            <></>
          )}
        </Stack>

        <Stack>
          <SimpleGrid columns={{ base: 2, md: 2, lg: 2, xl: 3 }} gap={4}>
            {policeCaseWithDetails.subjects.map((subject, index) => (
              <GridItem key={subject.patient.id}>
                {" "}
                <Stack
                  backgroundColor="blue.50"
                  justify="space-between"
                  align={"center"}
                  direction="row"
                  spacing="2"
                  px={4}
                  py={4}
                  borderRadius={"lg"}
                  shadow="md"
                >
                  <Text>{subject.person.name}</Text>
                  <Button
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${getDashboardName(
                          locationCategory
                        )}/document/upload?caseId=${
                          props.policeCaseId
                        }&subjectIndex=${index}`
                      );
                    }}
                  >
                    Upload documents
                  </Button>
                </Stack>
              </GridItem>
            ))}
          </SimpleGrid>
        </Stack>
      </Stack>

      <Stack
        spacing={6}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px"
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            backgroundColor: "gray.700"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.600",
            borderRadius: "12px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "gray.500"
          }
        }}
      >
        <Heading fontSize="xl">Documents</Heading>
        <Stack
          direction="row"
          width="100%"
          height="95vh"
          divider={<StackDivider width="2px" />}
        >
          <Stack width="100%">
            {policeCaseWithDetails.subjects.map((subject) => {
              return (
                <Stack
                  p="6"
                  borderWidth="3px"
                  borderColor="gray.200"
                  borderRadius="md"
                  borderStyle={"dashed"}
                >
                  <Text fontSize="xl" color="gray.700">
                    {subject.person.name}
                  </Text>
                  <SimpleGrid columns={1} gap={4}>
                    {subject.documents
                      .filter((document) => document.formData === null)
                      .map((document) => (
                        <GridItem key={document.id}>
                          <Stack
                            backgroundColor={
                              document.name.split(".")[1] === "pdf"
                                ? "red.50"
                                : "orange.50"
                            }
                            justify="space-between"
                            align={"center"}
                            direction="row"
                            spacing="2"
                            px={4}
                            py={4}
                            borderRadius={"lg"}
                            shadow="md"
                          >
                            <Stack
                              direction={{ base: "column", xl: "row" }}
                              align="center"
                              px="3"
                              width={"100%"}
                              alignItems={"center"}
                            >
                              {document.name.split(".")[1] === "pdf" ? (
                                <FaFilePdf />
                              ) : (
                                <FaImage />
                              )}
                              <Text isTruncated width={"100%"} fontSize="xs">
                                {document.name}
                              </Text>
                              {/* </Stack> */}

                              <Stack direction="row" spacing="2">
                                <IconButton
                                  colorScheme={
                                    document.name.split(".")[1] === "pdf"
                                      ? "red"
                                      : "orange"
                                  }
                                  aria-label="Check file"
                                  icon={<FaEye />}
                                  onClick={() => {
                                    setSelectedDocument(document);
                                  }}
                                />

                                {window.localStorage.getItem(
                                  "locationCategory"
                                ) === "Admin" ? (
                                  <IconButton
                                    colorScheme={
                                      document.name.split(".")[1] === "pdf"
                                        ? "red"
                                        : "orange"
                                    }
                                    aria-label="Download file"
                                    icon={<FaDownload />}
                                    onClick={() => {
                                      window.open(
                                        document.url,
                                        "_blank",
                                        "noopener, noreferrer"
                                      );
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                                <Tooltip
                                  label={"Remarks : " + document.remarks}
                                  placement="top"
                                >
                                  <IconButton
                                    aria-label="View Report"
                                    icon={<FaFile />}
                                  />
                                </Tooltip>
                              </Stack>
                            </Stack>
                          </Stack>
                        </GridItem>
                      ))}
                  </SimpleGrid>
                </Stack>
              );
            })}
          </Stack>

          <Stack width="100%">
            {selectedDocument ? (
              <Stack>
                <Text color="gray.400" p="2" textAlign={"center"}>
                  {selectedDocument.name}
                </Text>{" "}
                {selectedDocument.name.split(".")[1] === "pdf" ? (
                  <PdfViewerComponent url={selectedDocument.url} />
                ) : (
                  <ImageViewerComponent url={selectedDocument.url} />
                )}
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Center width="100%" height={"95vh"}>
      <Spinner />
    </Center>
  );
};
