import React from "react";
import {
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid
} from "@chakra-ui/react";
import dayjs from "dayjs";
interface PatientDetailComponentProps {
  patientName: string | null;
  patientId: string;
  patientContactNumber: string | null;
  patientNationalIdNumber: string | null;
  dateOfBirth: Date | null;
  gender: string;
}

export const PatientDetailComponent: React.FC<PatientDetailComponentProps> = (
  props: PatientDetailComponentProps
) => {
  return (
    <Stack direction="row" bgColor="white" p={5}>
      <SimpleGrid columns={3} spacingX="10" spacingY="5" width="100%">
        <FormControl isReadOnly>
          <FormLabel>
            <Text as="b">Patient Name</Text>
          </FormLabel>
          <Input
            size="md"
            defaultValue={props.patientName ? props.patientName : "Unknown"}
          />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>
            {" "}
            <Text as="b">Patient ID</Text>
          </FormLabel>
          <Input size="md" defaultValue={props.patientId} />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>
            <Text as="b">Patient Phone</Text>
          </FormLabel>
          <Input
            size="md"
            defaultValue={
              props.patientContactNumber
                ? props.patientContactNumber
                : "Unknown"
            }
          />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>
            {" "}
            <Text as="b">Patient NID</Text>
          </FormLabel>
          <Input
            size="md"
            defaultValue={
              props.patientNationalIdNumber
                ? props.patientNationalIdNumber
                : "Unknown"
            }
          />
        </FormControl>
        <FormControl isReadOnly>
          <FormLabel>
            <Text as="b">Date of Birth</Text>
          </FormLabel>
          <Input
            size="md"
            defaultValue={
              props.dateOfBirth
                ? dayjs(props.dateOfBirth).format("MMM DD, YYYY")
                : "Unknown"
            }
          />
        </FormControl>
        <FormControl isReadOnly>
          <FormLabel>
            <Text as="b">Gender</Text>
          </FormLabel>
          <Input size="md" defaultValue={props.gender} />
        </FormControl>
      </SimpleGrid>
    </Stack>
  );
};
