import React, { useEffect, useState } from "react";
import { Button, Center, Spinner, Stack } from "@chakra-ui/react";
import { useLazyGetRecordRequestsQuery } from "../api";
import { PaginationState } from "@tanstack/react-table";
import { TableGenerator } from "modules/core/components/table-generator.component";
import { RecordRequestStatusTag } from "./record-request-status.tag";
import { useNavigate } from "react-router-dom";

interface Props {}

export const FulfilledRecordRequestTable: React.FC<Props> = (props: Props) => {
  const [getRequestRecords, getRequestRecordsResult] =
    useLazyGetRecordRequestsQuery();

  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  useEffect(() => {
    getRequestRecords({
      recordRequest: {
        recordRequestStatusName: "Fulfilled"
      },
      pagination: {
        pageIndex,
        pageSize
      }
    });
  }, [pageIndex, pageSize]);

  return (
    <Stack>
      {getRequestRecordsResult.data ? (
        <TableGenerator
          data={getRequestRecordsResult.data.recordRequests.map(
            (recordRequest) => {
              return {
                ID: recordRequest.id,
                "Request Date": recordRequest.requestedAt,
                "Request Source": recordRequest.requestSource,
                Status: (
                  <RecordRequestStatusTag
                    status={recordRequest.recordRequestStatusName ?? "Unknown"}
                  />
                )
              };
            }
          )}
          headers={["ID", "Request Date", "Request Source", "Status"]}
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPagination={setPagination}
          pageCount={getRequestRecordsResult.data.count}
        />
      ) : (
        <Center width="100%" height="100px">
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
