import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Progress,
    ModalHeader,
    Center,
    Button,
    Spinner,
    Text,
    Tag,
    VStack
  } from "@chakra-ui/react";
  import { PDFDownloadLink } from "@react-pdf/renderer";
  
  interface PdfPrinterModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    document: React.ReactElement;
    fileName: string;
  }
  
  export const PdfPrinterModal: React.FC<
      PdfPrinterModalProps
  > = (props: PdfPrinterModalProps) => {
  
      return (
        <Modal
          closeOnOverlayClick={false}
          isOpen={props.isOpen}
          onClose={props.onClose}
        >
          <ModalOverlay />
            <ModalContent>
              <ModalHeader>
              </ModalHeader>
              <ModalBody>
                <PDFDownloadLink
                  document={props.document}
                  fileName={props.fileName}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Center>
                        <VStack>
                        <Text fontSize="xl" pb={6}>Your Report is Being Generated</Text>
                        <Spinner 
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                          pb={10}
                        />
                        <Text fontSize="md" pb={6}>This may take a few Minutes...</Text>
                        <Tag size="md" variant="solid" colorScheme="red">
                          PLEASE DO NOT CLOSE THIS WINDOW
                        </Tag>
                        </VStack>
                      </Center>
                    ) : (
                      <Center>
                        <VStack>
                        <Text fontSize="xl" pb={6}>Your Report is Ready to Download</Text>
                        <Button colorScheme="blue">{props.fileName}</Button>
                        </VStack>
                      </Center>
                    )
                  }
                </PDFDownloadLink>
              </ModalBody>
              <ModalFooter>
                  <Center>
                  <Button onClick={props.onClose} colorScheme="red" >Close</Button>
                  </Center>
              </ModalFooter>
            </ModalContent>
        </Modal>
      );
  };