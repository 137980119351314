import { api } from "app/api";
import * as ApiTypes from "./type";

const recordRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createRecordRequest: builder.mutation<
      ApiTypes.CreateRecordRequestResponse,
      ApiTypes.CreateRecordRequestRequest
    >({
      query: (data) => ({
        url: `/record-request/create`,
        method: `POST`,
        body: data
      }),
      invalidatesTags: ["Record Request"]
    }),

    getRecordRequest: builder.query<
      ApiTypes.GetRecordRequestByIdResponse,
      ApiTypes.GetRecordRequestByIdRequest
    >({
      query: ({ id }) => ({
        url: `/record-request/${id}/get`,
        method: `GET`
      }),
      providesTags: ["Record Request"]
    }),

    getRecordRequests: builder.query<
      ApiTypes.GetRecordRequestsResponse,
      ApiTypes.GetRecordRequestsRequest
    >({
      query: ({ recordRequest, pagination }) => ({
        url: `/record-request/list`,
        method: `POST`,
        body: {
          recordRequest,
          pagination
        }
      }),
      providesTags: ["Record Request"]
    }),

    updateRecordRequest: builder.mutation<
      ApiTypes.UpdateRecordRequestResponse,
      ApiTypes.UpdateRecordRequestRequest
    >({
      query: (data) => ({
        url: `/record-request/update`,
        method: `PUT`,
        body: data
      }),
      invalidatesTags: ["Record Request"]
    }),

    connectCaseWithRecord: builder.mutation<
      ApiTypes.ConnectCaseWithRecordResponse,
      ApiTypes.ConnectCaseWithRecordRequest
    >({
      query: ({ data }) => ({
        url: `/record-request/case/connect`,
        method: `POST`,
        body: { data }
      }),
      invalidatesTags: ["Record Request"]
    })
  }),
  overrideExisting: false
});

export const {
  useCreateRecordRequestMutation,
  useUpdateRecordRequestMutation,
  useLazyGetRecordRequestsQuery,
  useLazyGetRecordRequestQuery,
  useConnectCaseWithRecordMutation
} = recordRequestApi;
