import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  Link
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FaSearch } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyGetRecordRequestQuery } from "../api";
import { PoliceCaseWithDetails, RecordRequest } from "app/api/type";
import { ExistingPoliceCaseTableComponent } from "../components/existing-police-case-table.component";
import { useLazyUseSearchPoliceCasesQuery } from "modules/police-case/api";
import { PageContainerComponent } from "modules/core/components/page-container.component";
import { ConnectPoliceCaseTableComponent } from "../components/connect-police-case-table.component";

interface Props {}

export const ConnectRecordRequestPage: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const [getRequestRecord, getRequestRecordResult] =
    useLazyGetRecordRequestQuery();

  const [searchCase, searchCaseResult] = useLazyUseSearchPoliceCasesQuery();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearch = () => {
    searchCase({
      searchQuery
    });
  };

  useEffect(() => {
    searchCase({
      searchQuery: ""
    });
  }, []);

  const [recordRequest, setRecordRequest] = useState<RecordRequest>();
  const [policeCasesWithDetails, setPoliceCasesWithDetails] = useState<
    PoliceCaseWithDetails[]
  >([]);

  useEffect(() => {
    if (id) {
      getRequestRecord({ id });
    }
  }, []);

  useEffect(() => {
    if (getRequestRecordResult.isSuccess) {
      setRecordRequest(
        getRequestRecordResult.data.recordRequestWithCases.recordRequest
      );
      setPoliceCasesWithDetails(
        getRequestRecordResult.data.recordRequestWithCases
          .policeCasesWithDetails
      );
    }
  }, [getRequestRecordResult]);

  return (
    <Stack>
      <PageCardComponent>
        <Stack>
          <Heading fontSize={"2xl"} color="gray.500">
            Request ID: {id}
          </Heading>
          <Text color="gray.600">
            Request Source:{" "}
            {
              getRequestRecordResult.data?.recordRequestWithCases.recordRequest
                .requestSource
            }
          </Text>{" "}
          <Text color="gray.600">Record Reference: </Text>
          <Stack
            p={4}
            borderStyle="dashed"
            borderWidth={1}
            borderColor="gray.200"
          >
            <Text>
              {
                getRequestRecordResult.data?.recordRequestWithCases
                  .recordRequest.requestDocumentText
              }
            </Text>
          </Stack>
          <Stack>
            <Text color="gray.600">
              Request Letter Url:{" "}
              <Link
                color="blue"
                href={
                  getRequestRecordResult.data?.recordRequestWithCases
                    .recordRequest.requestDocumentUrl ?? ""
                }
              >
                Click here to download request letter
              </Link>
            </Text>
          </Stack>
        </Stack>
      </PageCardComponent>

      <PageCardComponent>
        <Text fontWeight={`bold`}>Connected Cases</Text>
        <ExistingPoliceCaseTableComponent
          policeCasesWithDetails={policeCasesWithDetails}
        />
      </PageCardComponent>

      <Divider />

      <Text>Search Query</Text>

      <PageCardComponent>
        <Stack direction={{ base: "column", lg: "row" }}>
          <InputGroup size="sm">
            <Input
              value={searchQuery}
              placeholder="Search with Case ID / Patient Name / Contact / Admission Department / NID"
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
            />

            <Button
              size="sm"
              leftIcon={<FaSearch />}
              colorScheme={"blue"}
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup>
        </Stack>
      </PageCardComponent>

      <PageCardComponent>
        {searchCaseResult.isSuccess && id && (
          <ConnectPoliceCaseTableComponent
            requestId={id}
            policeCasesWithDetails={
              searchCaseResult.data.policeCasesWithDetails
            }
          />
        )}{" "}
      </PageCardComponent>
    </Stack>
  );
};
