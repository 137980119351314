import React from "react";
import { Divider, Stack, Text } from "@chakra-ui/react";
import { CreateRecordRequestModal } from "../components/create-record-request.modal";
import { PendingRecordRequestTable } from "../components/pending-record-request.table";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FulfilledRecordRequestTable } from "../components/fulfilled-record-request.table";

interface Props {}

export const RecordRequestPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text fontWeight={"bold"}>Pending Requests</Text>
          <CreateRecordRequestModal />
        </Stack>
        <PendingRecordRequestTable />
      </PageCardComponent>

      <Divider />

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text fontWeight={"bold"}>Fulfilled Requests</Text>
        </Stack>
        <FulfilledRecordRequestTable />
      </PageCardComponent>
    </Stack>
  );
};
