import { IDashboard } from "app/types";
import { LogoComponent } from "app/components/logo.component";
import { PlaceholderPage } from "modules/core/pages/placeholder.page";
import { FaBriefcase, FaHistory, FaUserCircle } from "react-icons/fa";

export const superAdminDashboard: IDashboard = {
  dashboardName: "super-admin",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/super-admin/dashboard",
      content: <PlaceholderPage />
    },
    {
      name: "User List",
      link: "/super-admin/user/list",
      content: <PlaceholderPage />
    },
    {
      name: "Add User",
      link: "/super-admin/user/add",
      content: <PlaceholderPage />
    },
    {
      name: "User Details",
      link: "/super-admin/user/:userId/details",
      content: <PlaceholderPage />
    },
    {
      name: "Case List",
      link: "/super-admin/case/list",
      content: <PlaceholderPage />
    },
    {
      name: "Search Case",
      link: "/super-admin/case/search",
      content: <PlaceholderPage />
    },
    {
      name: "Case Details",
      link: "/super-admin/case/:caseId/details",
      content: <PlaceholderPage />
    },
    {
      name: "User Logs",
      link: "/super-admin/user/:userId/activity-log/list",
      content: <PlaceholderPage />
    },
    {
      name: "Case Logs",
      link: "/super-admin/case/:caseId/activity-log/list",
      content: <PlaceholderPage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/super-admin/dashboard",
      content: <PlaceholderPage />
    },
    {
      header: "Case Management",
      icon: FaBriefcase,
      pages: [
        {
          name: "Case List",
          link: "/super-admin/case/list",
          content: <PlaceholderPage />
        },
        {
          name: "Search Case",
          link: "/super-admin/case/search",
          content: <PlaceholderPage />
        }
        // {
        //   name: "Case Details",
        //   link: "/super-admin/case/default/details",
        //   content: <PlaceholderPage />
        // }
      ]
    },
    {
      header: "User Management",
      icon: FaUserCircle,
      pages: [
        {
          name: "User List",
          link: "/super-admin/user/list",
          content: <PlaceholderPage />
        },
        {
          name: "Add User",
          link: "/super-admin/user/add",
          content: <PlaceholderPage />
        },
        {
          name: "User Details",
          link: "/super-admin/user/default/details",
          content: <PlaceholderPage />
        }
      ]
    },

    {
      header: "Activity Logs",
      icon: FaHistory,
      pages: [
        {
          name: "User Logs",
          link: "/super-admin/user/default/activity-log/list",
          content: <PlaceholderPage />
        },
        {
          name: "Case Logs",
          link: "/super-admin/case/default/activity-log/list",
          content: <PlaceholderPage />
        }
      ]
    }
  ]
};
