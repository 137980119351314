import { Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

interface PdfViewerComponentProps {
  url: string;
}

export const PdfViewerComponent: React.FC<PdfViewerComponentProps> = (
  props: PdfViewerComponentProps
) => {
  return (
    <Stack maxWidth={"100%"} maxHeight="297mm" overflow={"hidden"}>
      <Document file={`${props.url}`}>
        <Page pageNumber={1} renderTextLayer={false} />
      </Document>{" "}
    </Stack>
  );
};
