import React from "react";
import { Image } from "@chakra-ui/react";
interface ImageViewerComponentProps {
  url: string;
}

export const ImageViewerComponent: React.FC<ImageViewerComponentProps> = (
  props: ImageViewerComponentProps
) => {
  return <Image src={props.url} position="sticky" />;
};
