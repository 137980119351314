import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Text,
  Switch,
  Heading,
  Divider
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAddPatientToCaseMutation } from "../api";
import dayjs from "dayjs";
import { Select } from "chakra-react-select";
import { genderList, patientTypeList, religions } from "../constants";
import { departmentOptions } from "modules/user/constants";
import { useGetAllDepartmentsQuery } from "modules/user/api";

interface AddPatientComponentProps {
  policeCaseId: string;
}

export const AddPatientComponent: React.FC<AddPatientComponentProps> = (
  props: AddPatientComponentProps
) => {
  const [addPatient, addPatientResult] = useAddPatientToCaseMutation();
  const getAllDepartmentsResult = useGetAllDepartmentsQuery({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [gender, setGender] = useState<{
    label: string;
    value: string;
  }>();

  const [patientType, setPatientType] = useState<{
    label: string;
    value: string;
  }>();

  const [religion, setReligion] = useState<{
    label: string;
    value: string;
  }>();

  const [name, setName] = useState<string>();
  const [nationalIdNumber, setNationalIdNumber] = useState<string>();
  const [contactNumber, setContactNumber] = useState<string>();

  const [showDateOfBirth, setShowDateOfBirth] = useState(true);
  const [isDateOfBirthEstimated, setIsDateOfBirthEstimated] =
    useState<boolean>(false);

  const [age, setAge] = useState<number>();
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const [fatherName, setFatherName] = useState<string>();
  const [spouseName, setSpouseName] = useState<string>();
  const [address1, setAddress1] = useState<string>();
  const [disease, setDisease] = useState<string>();
  const [ward, setWard] = useState<string>();

  const [ticketId, setTicketId] = useState<string>();

  const [admittedOn, setAdmittedOn] = useState<Date>();
  const [selectedDepartmentName, setSelectedDepartmentName] = useState<{
    label: string;
    value: string;
  }>();

  const [departmentList, setDepartmentList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  // If age is edited. change the date of birth
  useEffect(() => {
    if (age && isDateOfBirthEstimated) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setDateOfBirth(dayjs().subtract(age, "year").toDate());
      }
    }
  }, [age]);

  // If date of birth is updated, update the age accordingly (only year)
  useEffect(() => {
    if (dateOfBirth) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setAge(dayjs().diff(dayjs(dateOfBirth), "year"));
      }
    }
  }, [dateOfBirth]);

  useEffect(() => {
    if (getAllDepartmentsResult.data) {
      setDepartmentList(
        getAllDepartmentsResult.data.locations.map((location) => {
          return {
            label: location.name,
            value: location.id
          };
        })
      );
    }
  }, [getAllDepartmentsResult]);

  const navigate = useNavigate();

  const handleAddPatient = () => {
    addPatient({
      data: {
        policeCase: {
          id: props.policeCaseId
        },
        patientCaseProfile: {
          patientCategoryName: patientType?.value ? patientType.value : "",
          disease: disease ? disease : null,
          admissionDate: admittedOn ? admittedOn : new Date(),
          admissionDepartment: selectedDepartmentName?.value
            ? selectedDepartmentName.value
            : null,
          ward: ward ? ward : null,
          ticketId: ticketId ? ticketId : null
        },
        person: {
          name: name ? name : null,
          dateOfBirth: dateOfBirth ? dateOfBirth : null,
          isDateOfBirthEstimated,
          contactNumber: contactNumber ? contactNumber : null,
          passportNumber: null,
          gender: gender?.value as "male" | "female" | "other",
          nationalIdNumber: nationalIdNumber ? nationalIdNumber : null,
          dateOfDeath: null,
          isDateOfDeathEstimated: null,
          religionName: religion?.value ? religion.value : null,
          // fatherOrSpouse: fatherOrSpouseName ? fatherOrSpouseName : null
          fatherName: fatherName ? fatherName : null,
          spouseName: spouseName ? spouseName : null
        },
        address: {
          addressLine1: address1 ? address1 : null,
          addressLine2: null,
          postalCode: null,
          subdistrictCode: null,
          districtCode: null,
          divisionCode: null,
          unionCode: null
        }
      }
    });
  };

  useEffect(() => {
    if (addPatientResult.isSuccess) {
      onClose();
    }
  }, [addPatientResult]);

  return (
    <>
      <Button colorScheme={"blue"} onClick={onOpen} leftIcon={<FaPlus />}>
        Add Patient
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Patient Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width="100%" spacing="4">
              <Heading fontSize="md">Personal Information</Heading>
              <Stack direction="row">
                <FormControl>
                  <FormLabel>Patient Name</FormLabel>
                  <Input
                    name="name"
                    value={name}
                    placeholder="Name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Patient Contact</FormLabel>
                  <Input
                    value={contactNumber}
                    placeholder="01XXXXXXXXX"
                    onChange={(e) => {
                      setContactNumber(e.target.value);
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row">
                <FormControl width="100%" isRequired>
                  <FormLabel>Gender</FormLabel>
                  <Select
                    selectedOptionStyle="check"
                    options={genderList}
                    value={gender}
                    onChange={(event) => {
                      if (event) {
                        setGender({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Patient National ID</FormLabel>
                  <Input
                    name="national-id"
                    type="number"
                    value={nationalIdNumber}
                    placeholder="National Id Number"
                    onChange={(e) => {
                      setNationalIdNumber(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Religion</FormLabel>
                  <Select
                    size="sm"
                    selectedOptionStyle="check"
                    options={religions}
                    value={religion}
                    onChange={(event) => {
                      if (event) {
                        setReligion({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row">
                {showDateOfBirth && (
                  <FormControl width="100%">
                    <FormLabel>Date Of Birth</FormLabel>

                    <DatePicker
                      selected={dateOfBirth}
                      onChange={(date) => date && setDateOfBirth(date)}
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                    />
                  </FormControl>
                )}
                <FormControl width="100%">
                  <FormLabel>
                    <Stack direction="row" spacing="2" alignItems={"center"}>
                      <Text>Estimated Age</Text>
                      <Switch
                        checked={isDateOfBirthEstimated}
                        onChange={() => {
                          setIsDateOfBirthEstimated(!isDateOfBirthEstimated);
                          setShowDateOfBirth(!showDateOfBirth);
                        }}
                      ></Switch>
                    </Stack>
                  </FormLabel>

                  <Input
                    size="sm"
                    value={age}
                    type="number"
                    min="0"
                    step="1"
                    onChange={(event) => {
                      setAge(event.target.valueAsNumber);
                    }}
                    disabled={!isDateOfBirthEstimated}
                  />
                </FormControl>{" "}
              </Stack>

              <Stack direction="row">
                <FormControl w="60%">
                  <FormLabel>Father's name</FormLabel>
                  <Input
                    value={fatherName}
                    placeholder="Father Name"
                    onChange={(e) => {
                      setFatherName(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl w="60%">
                  <FormLabel>Spouse's name</FormLabel>
                  <Input
                    value={spouseName}
                    placeholder="Spouse Name"
                    onChange={(e) => {
                      setSpouseName(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    value={address1}
                    placeholder="Full address in details"
                    onChange={(e) => {
                      setAddress1(e.target.value);
                    }}
                  />
                </FormControl>
              </Stack>
              <Divider />
              <Heading fontSize="md">Admission Information</Heading>
              <FormControl width="100%" isRequired>
                <FormLabel>Ticket ID (From Hospital)</FormLabel>
                <Input
                  size="sm"
                  value={ticketId}
                  onChange={(event) => {
                    setTicketId(event.target.value);
                  }}
                />
              </FormControl>
              <Stack direction="row">
                <FormControl width="100%" isRequired>
                  <FormLabel>Patient Category</FormLabel>
                  <Select
                    size="sm"
                    selectedOptionStyle="check"
                    options={patientTypeList}
                    value={patientType}
                    onChange={(event) => {
                      if (event) {
                        setPatientType({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>
                <FormControl width="100%" isRequired>
                  <FormLabel>Admitted On</FormLabel>

                  <DatePicker
                    selected={admittedOn}
                    onChange={(date) => date && setAdmittedOn(date)}
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Department Name</FormLabel>
                  <Select
                    selectedOptionStyle="check"
                    options={departmentList}
                    value={selectedDepartmentName}
                    onChange={(event) => {
                      if (event) {
                        setSelectedDepartmentName({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                </FormControl>
                <FormControl width="100%" isRequired>
                  <FormLabel>Ward</FormLabel>
                  <Input
                    size="sm"
                    value={ward}
                    onChange={(event) => {
                      setWard(event.target.value);
                    }}
                  />
                </FormControl>
              </Stack>
              <FormControl>
                <FormLabel>Disease/Problem Description</FormLabel>
                <Textarea
                  size="sm"
                  value={disease}
                  placeholder="Patient problem description"
                  onChange={(e) => {
                    setDisease(e.target.value);
                  }}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleAddPatient}
              isLoading={addPatientResult.isLoading}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
