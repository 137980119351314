import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { Select } from "chakra-react-select";
import { useCreateUserMutation, useGetAllDepartmentsQuery } from "../api";

interface Props {}

const designationOptions = [
  { value: "Doctor", label: "Doctor" },
  { value: "Nurse", label: "Nurse" },
  { value: "Other", label: "Other" }
];
export const CreateUserFormComponent: React.FC<Props> = (props: Props) => {
  const [createUser, createUserResult] = useCreateUserMutation();
  const getAllDepartmentsResult = useGetAllDepartmentsQuery({});
  const toast = useToast();

  const [name, setName] = useState<string>();
  const [contactNumber, setContactNumber] = useState<string>();
  const [nationalIdNumber, setNationalIdNumber] = useState<string>();
  const [selectedDesignationName, setSelectedDesignationName] = useState<{
    label: string;
    value: string;
  }>();
  const [departmentList, setDepartmentList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [selectedDepartmentName, setSelectedDepartmentName] = useState<{
    label: string;
    value: string;
  }>();
  const [medicalCertificationNumber, setMedicalCertificationNumber] =
    useState<string>();
  const [employmentId, setEmploymentId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();

  useEffect(() => {
    if (getAllDepartmentsResult.data) {
      setDepartmentList(
        getAllDepartmentsResult.data.locations.map((location) => {
          return {
            label: location.name,
            value: location.id
          };
        })
      );
    }
  }, [getAllDepartmentsResult]);

  const handleUserCreation = () => {
    if (
      name &&
      contactNumber &&
      nationalIdNumber &&
      selectedDesignationName &&
      employmentId &&
      ((selectedDesignationName.value === "Doctor" &&
        medicalCertificationNumber) ||
        (selectedDesignationName.value === "Nurse" &&
          medicalCertificationNumber) ||
        (selectedDesignationName.value === "Others" &&
          !medicalCertificationNumber)) &&
      email &&
      password &&
      passwordConfirmation &&
      password === passwordConfirmation
    ) {
      createUser({
        data: {
          user: {
            email,
            password,
            designationName: selectedDesignationName.value,
            employmentId,
            medicalCertificationNumber: medicalCertificationNumber
              ? medicalCertificationNumber
              : null,
            departmentName: selectedDepartmentName?.value
              ? selectedDepartmentName.value
              : null
          },
          person: {
            name: name,
            contactNumber,
            nationalIdNumber,
            passportNumber: null,
            gender: null,
            dateOfBirth: null,
            isDateOfBirthEstimated: null,
            dateOfDeath: null,
            isDateOfDeathEstimated: null,
            religionName: null,
            fatherName: null,
            spouseName: null
          },
          address: {
            addressLine1: null,
            addressLine2: null,
            postalCode: null,
            unionCode: null,
            subdistrictCode: null,
            districtCode: null,
            divisionCode: null
          }
        }
      });
    }
  };

  const handleReset = () => {
    setName("");
    setContactNumber("");
    setNationalIdNumber("");
    setSelectedDesignationName({ label: "", value: "" });
    setSelectedDepartmentName({ label: "", value: "" });
    setMedicalCertificationNumber("");
    setEmploymentId("");
    setEmail("");
    setPassword("");
    setPasswordConfirmation("");
  };

  useEffect(() => {
    if (createUserResult.isSuccess) {
      toast({
        title: "Success",
        description: "User created successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      handleReset();
    }
  }, [createUserResult]);

  return (
    <Stack>
      <PageCardComponent>
        <Heading fontSize={"xl"}> Personal Information</Heading>
        <Stack direction="row">
          {" "}
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Contact Number</FormLabel>
            <Input
              value={contactNumber}
              onChange={(event) => {
                setContactNumber(event.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>National ID Number</FormLabel>
            <Input
              value={nationalIdNumber}
              onChange={(event) => {
                setNationalIdNumber(event.target.value);
              }}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Heading fontSize={"xl"}>Professional Information</Heading>
        <Stack direction="row">
          <FormControl>
            <FormLabel>Department Name</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={departmentList}
              value={selectedDepartmentName}
              onChange={(event) => {
                if (event) {
                  setSelectedDepartmentName({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Profession</FormLabel>
            <Select
              selectedOptionStyle="check"
              options={designationOptions}
              value={selectedDesignationName}
              onChange={(event) => {
                if (event) {
                  setSelectedDesignationName({
                    label: event.label,
                    value: event.value
                  });
                }
              }}
            />
          </FormControl>
        </Stack>
        <Stack direction="row">
          <FormControl>
            <FormLabel>HRIS Number</FormLabel>
            <Input
              value={employmentId}
              onChange={(event) => {
                setEmploymentId(event.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>BMDC/BNC Number (Doctor/Nurse)</FormLabel>
            <Input
              type="text"
              value={medicalCertificationNumber}
              isDisabled={selectedDesignationName?.value === "Others"}
              onChange={(event) => {
                setMedicalCertificationNumber(event.target.value);
              }}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Heading fontSize={"xl"}>Account Information</Heading>
        <Stack direction="row">
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              value={passwordConfirmation}
              onChange={(event) => {
                setPasswordConfirmation(event.target.value);
              }}
            />
          </FormControl>
        </Stack>
        <Divider pb={4} />
        <Stack direction="row">
          <Button
            onClick={handleUserCreation}
            isLoading={createUserResult.isLoading}
            isDisabled={
              !(
                name &&
                contactNumber &&
                nationalIdNumber &&
                selectedDesignationName &&
                ((selectedDesignationName.value === "Doctor" &&
                  medicalCertificationNumber) ||
                  (selectedDesignationName.value !== "Doctor" &&
                    !medicalCertificationNumber)) &&
                email &&
                password &&
                passwordConfirmation &&
                password === passwordConfirmation
              )
            }
            width="100%"
          >
            Create User
          </Button>
          <Button onClick={handleReset} colorScheme={"red"} width="100%">
            Reset
          </Button>
        </Stack>
      </PageCardComponent>
    </Stack>
  );
};
