import React from "react";
import { Box, Heading, Stack, Text, Tooltip } from "@chakra-ui/react";
import { Log, Person, PoliceCase, User, Patient } from "app/api/type";
import dayjs from "dayjs";

interface Props {
  logWithDetails: {
    log: Log;
    userWithDetails: {
      user: Omit<User, "password">;
      person: Person;
    };
    patientWithDetails: {
      patient: Patient;
      person: Person;
    } | null;
    policeCase: PoliceCase | null;
  };
}
export const LogComponent: React.FC<Props> = (props: Props) => {
  const UserTooltip = () => {
    return (
      <Stack p="4">
        <Text>User ID: {props.logWithDetails.userWithDetails.user.id}</Text>
        <Text>Email: {props.logWithDetails.userWithDetails.user.email}</Text>
        <Text>
          Occupation:{" "}
          {props.logWithDetails.userWithDetails.user.designationName}
        </Text>
        <Text>
          BMDC/BNC Number:{" "}
          {props.logWithDetails.userWithDetails.user.medicalCertificationNumber
            ? props.logWithDetails.userWithDetails.user
                .medicalCertificationNumber
            : "Unknown"}
        </Text>
        <Text>
          Department:{" "}
          {props.logWithDetails.userWithDetails.user.departmentName
            ? props.logWithDetails.userWithDetails.user.departmentName
            : "Unknown"}
        </Text>
      </Stack>
    );
  };

  const PatientTooltip = () => {
    return (
      <Stack p="4">
        <Text>
          Name:{" "}
          {props.logWithDetails.patientWithDetails?.person.name
            ? props.logWithDetails.patientWithDetails?.person.name
            : "Unknown"}
        </Text>
        <Text>
          Contact:{" "}
          {props.logWithDetails.patientWithDetails?.person.contactNumber
            ? props.logWithDetails.patientWithDetails?.person.contactNumber
            : "Unknown"}
        </Text>

        <Text>
          Admitted On:{" "}
          {dayjs(
            props.logWithDetails.patientWithDetails?.patient.createdAt
          ).format("DD MMM YYYY")}
        </Text>
      </Stack>
    );
  };

  return (
    <Stack
      p="4"
      borderRadius={"md"}
      bgColor="white"
      boxShadow={"md"}
      width="100%"
      borderStyle={"dashed"}
      borderColor={"gray.200"}
    >
      <Stack direction="row" justify={"space-between"} alignItems="center">
        <Stack width="75%">
          <Heading color={"gray.500"} fontSize="sm">
            Case {props.logWithDetails.log.policeCaseId}
          </Heading>{" "}
          <Text fontSize="xs">
            Logged at:{" "}
            {dayjs(props.logWithDetails.log.createdAt).format(
              "DD MMM YYYY hh:mm:ss A"
            )}
          </Text>
          <Heading fontSize="md" color="gray.700" as="span">
            {props.logWithDetails.log.action.split(" ").map((word) => {
              if (word.startsWith("PT")) {
                return (
                  <Tooltip label={<PatientTooltip />}>
                    <Text as="span" color="green.500">
                      {word}{" "}
                    </Text>
                  </Tooltip>
                );
              } else {
                return <Text as="span">{word} </Text>;
              }
            })}
          </Heading>
        </Stack>
        <Stack alignItems={"flex-end"} width="25%">
          <Text fontSize="xs" color="gray.600">
            Logged By:{" "}
          </Text>
          <Tooltip label={<UserTooltip />} placement="left-start">
            <Heading fontSize="lg" color="orange.600">
              {props.logWithDetails.userWithDetails.person.name}
            </Heading>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};
