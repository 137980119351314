import React, { useEffect } from "react";
import {
  Stack,
  Input,
  Grid,
  FormControl,
  FormLabel,
  Box,
  Text,
  Heading
} from "@chakra-ui/react";
import { useGetAllCasesCountQuery } from "../api";

interface DashboardComponentProps {}

export const DashboardComponent: React.FC<DashboardComponentProps> = (
  props: DashboardComponentProps
) => {
  const getAllCasesStatResult = useGetAllCasesCountQuery({});
  return (
    <Stack>
      <Grid templateColumns="repeat(2, 1fr)" gap={8}>
        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <FormLabel>Total Cases</FormLabel>
          <Box>
            <Stack>
              <Heading>
                {getAllCasesStatResult.data?.patientStatistics.totalCases
                  ? getAllCasesStatResult.data?.patientStatistics.totalCases
                  : 0}
              </Heading>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <FormLabel>Total OPD Patients</FormLabel>
          <Box>
            <Stack>
              <Heading>
                {getAllCasesStatResult.data?.patientStatistics.OPD
                  ? getAllCasesStatResult.data?.patientStatistics.OPD
                  : 0}
              </Heading>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <FormLabel>Total IPD Patients</FormLabel>
          <Box>
            <Stack>
              <Heading>
                {getAllCasesStatResult.data?.patientStatistics.IPD
                  ? getAllCasesStatResult.data?.patientStatistics.IPD
                  : 0}
              </Heading>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <FormLabel>Total Dead Patients</FormLabel>
          <Box>
            <Stack>
              <Heading>
                {getAllCasesStatResult.data?.patientStatistics.Dead
                  ? getAllCasesStatResult.data?.patientStatistics.Dead
                  : 0}
              </Heading>
            </Stack>
          </Box>
        </FormControl>
      </Grid>
    </Stack>
  );
};
