import React, { useEffect } from "react";
import { ReactNode, useState, useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";

import {
  Flex,
  useToast,
  Stack,
  Text,
  IconButton,
  Button,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Textarea,
  Input
} from "@chakra-ui/react";
import {
  FaDownload,
  FaRegFileImage,
  FaRegFilePdf,
  FaTrash
} from "react-icons/fa";
import {
  useAddPatientInformationToCaseProfileMutation,
  useGetDocumentsFromCaseQuery,
  useUploadDocumentMutation
} from "../api";
import { Document } from "app/api/type";
import { Select } from "chakra-react-select";

interface FileUploadTabProps {
  policeCaseId: string;
  patientId: string;
  documentTypeName: string;
  allowedFileTypes: Accept;
  allowMultiple: boolean;
  maxFiles: number;
  uploadIcon: ReactNode;
  instructionText: string;
  instructionSubtext?: string;
  dragAcceptText: string;
  dragRejectText: string;
}

function objectToJson(obj: any): string {
  return JSON.stringify(obj);
}

function useJsonState<T>(initialValue: T) {
  const [data, setData] = useState<T>(initialValue);

  const setJsonData = (jsonData: string) => {
    try {
      const parsedData = JSON.parse(jsonData) as T;
      setData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON data:", error);
    }
  };

  return [data, setJsonData] as const;
}

export const FileUploadTab: React.FC<FileUploadTabProps> = (
  props: FileUploadTabProps
) => {
  const [uploadDocument, uploadDocumentResult] = useUploadDocumentMutation();
  const [addInformation, addInformationResult] =
    useAddPatientInformationToCaseProfileMutation();
  const getDocumentsFromCaseResult = useGetDocumentsFromCaseQuery({
    policeCaseId: props.policeCaseId,
    patientId: props.patientId,
    documentTypeName: props.documentTypeName
  });
  const [shouldShowFileUploader, setShouldShowFileUploader] =
    useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [existingDocuments, setExistingDocuments] = useState<Document[]>([]);
  const [addComments, setAddComments] = useState<string>();
  const [information, setInformation] = useState<{
    [key: string]: string;
  }>();

  const [investigation, setInvestigation] = useState<{
    [key: string]: string;
  }>();

  const [existingInformation, setExistingInformation] = useJsonState<{
    [key: string]: string;
  }>({});
  // const [existingInformation, setExistingInformation] = useState<
  //   any
  // >();

  useEffect(() => {
    if (getDocumentsFromCaseResult.isSuccess) {
      setExistingDocuments(getDocumentsFromCaseResult.data.documents);
    }
  }, [getDocumentsFromCaseResult.data, getDocumentsFromCaseResult]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.length) {
      setFiles(() => [
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      ]);
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted.");
      reader.onerror = () => console.log("File reading has failed.");
      reader.onload = () => {
        setUploadedFile(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onDrop,
    maxFiles: props.maxFiles,
    accept: props.allowedFileTypes,
    multiple: props.allowMultiple,
    useFsAccessApi: false // Bug Fix: https://github.com/react-dropzone/react-dropzone/issues/1190
  });

  const dropzoneBackgroundColor = isDragActive
    ? isDragAccept
      ? "green.50"
      : "red.50"
    : "gray.100";

  const toast = useToast();

  const handleFileUpload = () => {
    if (uploadedFile) {
      const remarksValue = addComments || "";
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("policeCaseId", props.policeCaseId);
      formData.append("patientId", props.patientId);
      formData.append("documentTypeName", props.documentTypeName);
      formData.append("remarks", remarksValue);
      console.log(formData);
      uploadDocument({ data: formData });
    }
  };

  const handleInformationUpload = () => {
    addInformation({
      data: {
        policeCaseId: props.policeCaseId,
        documentTypeName: props.documentTypeName,
        patientId: props.patientId,
        formData: information
      }
    });
    setInformation({});
    console.log(information);
  };

  const handleInvestigationUpload = () => {
    addInformation({
      data: {
        policeCaseId: props.policeCaseId,
        documentTypeName: props.documentTypeName,
        patientId: props.patientId,
        formData: investigation
      }
    });
    setInvestigation({});
    console.log(information);
  };
  return (
    <Stack spacing="6" py="6">
      <SimpleGrid columns={3} gap={4}>
        {existingDocuments
          .filter((existingDocument) => existingDocument.formData === null)
          .map((document) => {
            return (
              <GridItem key={document.id}>
                <Stack
                  backgroundColor={
                    document.name.split(".")[1] === "pdf" ? "red.50" : "blue.50"
                  }
                  justify="space-between"
                  align={"center"}
                  direction="row"
                  spacing="2"
                  px={4}
                  py={4}
                  borderRadius={"lg"}
                  shadow="md"
                >
                  <Stack direction="row" align="center" px="3" width="80%">
                    {document.name.split(".")[1] === "pdf" ? (
                      <FaRegFilePdf />
                    ) : (
                      <FaRegFileImage />
                    )}
                    <Text width="80%" isTruncated fontSize="xs">
                      {document.name}
                    </Text>
                  </Stack>

                  <Stack direction="row" align="center" px="3" width="20%">
                    {window.localStorage.getItem("locationCategory") ===
                    "Admin" ? (
                      <IconButton
                        colorScheme={
                          document.name.split(".")[1] === "pdf"
                            ? "red"
                            : "orange"
                        }
                        aria-label="Download file"
                        icon={<FaDownload />}
                        onClick={() => {
                          window.open(
                            document.url,
                            "_blank",
                            "noopener, noreferrer"
                          );
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>{" "}
              </GridItem>
            );
          })}
      </SimpleGrid>

      <SimpleGrid columns={1} gap={4}>
        {existingDocuments
          .filter((existingDocument) => existingDocument.formData !== null)
          .map((document) => {
            const jsonResult = objectToJson(document.formData);

            return (
              <GridItem key={document.id}>
                <Stack
                  backgroundColor={
                    document.name.split(".")[1] === "pdf" ? "red.50" : "blue.50"
                  }
                  justify="space-between"
                  align={"center"}
                  direction="row"
                  spacing="2"
                  px={4}
                  py={4}
                  borderRadius={"lg"}
                  shadow="md"
                >
                  <Stack direction="row" align="center" px="3" width="80%">
                    <Text width="80%" isTruncated fontSize="xs">
                      {document.name}
                      {Object.entries(document.formData).map(([key, value]) => {
                        return <Text key={key}>{`${key}: ${value}`}</Text>;
                      })}
                    </Text>
                  </Stack>
                </Stack>{" "}
              </GridItem>
            );
          })}
      </SimpleGrid>
      {props.documentTypeName === "Injury Note" ? (
        <Stack direction={"row-reverse"}>
          <Button
            onClick={() => {
              setShouldShowFileUploader(false);
            }}
          >
            Upload Information
          </Button>
          <Button
            onClick={() => {
              setShouldShowFileUploader(true);
            }}
          >
            Upload Documents
          </Button>
        </Stack>
      ) : props.documentTypeName !== "Investigation Report" ? (
        <Stack direction={"row-reverse"}>
          <Button
            onClick={() => {
              setShouldShowFileUploader(true);
            }}
          >
            Upload Documents
          </Button>
        </Stack>
      ) : (
        <Stack direction={"row-reverse"}>
          <Button
            onClick={() => {
              setShouldShowFileUploader(false);
            }}
          >
            Upload Investigations
          </Button>
        </Stack>
      )}

      {shouldShowFileUploader ? (
        <>
          <Flex
            align="center"
            justify="center"
            bgColor={dropzoneBackgroundColor}
            color={isDragActive ? "gray.900" : "gray.800"}
            _hover={{
              color: "gray.900",
              borderColor: "gray.800"
            }}
            borderStyle="solid"
            borderWidth="2px"
            borderColor={isDragActive ? "gray.500" : "gray.300"}
            borderRadius="lg"
            width="100%"
            height="64"
            minH="100%"
            cursor="pointer"
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />

            <Stack alignItems={"center"} textAlign="center" p={6}>
              {props.uploadIcon}
              {!isDragActive && <Text>{props.instructionText}</Text>}
              {!isDragActive &&
                (props.instructionSubtext ?? (
                  <Text fontSize="xs">{props.instructionSubtext}</Text>
                ))}
              {isDragAccept && <Text>{props.dragAcceptText}</Text>}
              {isDragReject && <Text>{props.dragRejectText}</Text>}
            </Stack>
          </Flex>
          <Stack>
            {files.map((file: File, index) => {
              if (files.length === index + 1)
                return (
                  <Stack
                    direction="row"
                    border={"solid"}
                    borderWidth="2px"
                    borderColor={"gray.400"}
                    _hover={{
                      borderColor: "gray.600",
                      backgroundColor: "gray.100"
                    }}
                    align="center"
                    key={file.name}
                    p="3"
                    justify={"space-between"}
                    borderRadius="lg"
                  >
                    <Stack direction="row" align="center" px="3">
                      {file.type === "application/pdf" ? (
                        <FaRegFilePdf />
                      ) : (
                        <FaRegFileImage />
                      )}
                      <Text>{file.name}</Text>
                    </Stack>

                    <IconButton
                      aria-label="Delete"
                      icon={<FaTrash />}
                      w="10px"
                      colorScheme="red"
                      size="sm"
                      onClick={() => removeFile(file.name)}
                    />
                  </Stack>
                );
              else return <></>;
            })}
          </Stack>
          <FormControl>
            <FormLabel>Remarks</FormLabel>
            <Textarea
              value={addComments}
              onChange={(event) => {
                setAddComments(event.target.value);
              }}
            />
          </FormControl>
          <Stack>
            <Button
              onClick={handleFileUpload}
              isLoading={uploadDocumentResult.isLoading}
              isDisabled={!uploadedFile}
            >
              Upload Document
            </Button>
          </Stack>
        </>
      ) : props.documentTypeName !== "Investigation Report" ? (
        <>
          <FormControl>
            <FormLabel>Type of mark</FormLabel>
            <Select
              placeholder="Select option"
              required={true}
              // value={existingInformation["Type of mark"] ? existingInformation["Type of mark"] : null}
              options={[
                "Cut",
                "Burn",
                "Bruise",
                "Blister",
                "Color Change",
                "Swelling",
                "Laceration",
                "Other"
              ].map((option) => {
                return { label: option, value: option };
              })}
              onChange={(value) => {
                if (value)
                  setInformation({
                    ...information,
                    "Type of mark": value.value
                  });
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>No of the wound</FormLabel>
            <Input
              placeholder="number of wound(s)"
              required={true}
              min={0}
              value={
                information?.["No of the wound"]
                  ? information?.["No of the wound"]
                  : ""
              }
              type="number"
              onChange={(e) => {
                if (e)
                  setInformation({
                    ...information,
                    "No of the wound": e.target.value
                  });
              }}
            />
          </FormControl>
          <Text fontSize={32}>Site of Injury</Text>
          <Stack direction={"row"}>
            <FormControl>
              <FormLabel>Length (cm)</FormLabel>
              <Input
                type="number"
                min={0}
                value={information?.["Length"] ? information?.["Length"] : ""}
                onChange={(e) => {
                  if (e)
                    setInformation({ ...information, Length: e.target.value });
                }}
              ></Input>
            </FormControl>

            <FormControl>
              <FormLabel>Width (cm)</FormLabel>
              <Input
                type="number"
                min={0}
                value={information?.["Width"] ? information?.["Width"] : ""}
                onChange={(e) => {
                  if (e)
                    setInformation({ ...information, Width: e.target.value });
                }}
              ></Input>
            </FormControl>

            <FormControl>
              <FormLabel>Depth (cm)</FormLabel>
              <Input
                type="number"
                min={0}
                value={information?.["Depth"] ? information?.["Depth"] : ""}
                onChange={(e) => {
                  if (e)
                    setInformation({ ...information, Depth: e.target.value });
                }}
              ></Input>
            </FormControl>
          </Stack>

          <FormControl>
            <FormLabel>Type of weapon</FormLabel>
            <Input
              type="text"
              onChange={(e) => {
                if (e)
                  setInformation({
                    ...information,
                    "Type of weapon": e.target.value
                  });
              }}
            ></Input>
          </FormControl>

          <FormControl>
            <FormLabel>Nature of Injury</FormLabel>
            <Input
              type="text"
              onChange={(e) => {
                if (e)
                  setInformation({
                    ...information,
                    "Nature of Injury": e.target.value
                  });
              }}
            ></Input>
          </FormControl>
          <Button onClick={handleInformationUpload}>Upload Information</Button>
        </>
      ) : (
        <>
          <FormControl>
            <FormLabel>Doctor's Investigation</FormLabel>
            <Input
              type="text"
              onChange={(e) => {
                if (e)
                  setInvestigation({
                    ...investigation,
                    Investigation: e.target.value
                  });
              }}
            ></Input>
          </FormControl>
          <Button onClick={handleInvestigationUpload} type="submit">
            Save
          </Button>
        </>
      )}
    </Stack>
  );
};
