import { api } from "app/api";
import * as ApiTypes from "./type";

const policeCaseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCase: builder.mutation<
      ApiTypes.CreateCaseResponse,
      ApiTypes.CreateCaseRequest
    >({
      query: ({ data }) => ({
        url: `/police-case/create`,
        method: `POST`,
        body: { data }
      })
    }),

    getAllCases: builder.query<
      ApiTypes.GetAllCasesResponse,
      ApiTypes.GetAllCasesRequest
    >({
      query: () => ({
        url: `/police-case/list/get`,
        method: `GET`
      })
    }),

    getPoliceCaseDetails: builder.query<
      ApiTypes.GetPoliceCaseDetailsResponse,
      ApiTypes.GetPoliceCaseDetailsRequest
    >({
      query: ({ id }) => ({
        url: `/police-case/details/get`,
        method: `GET`,
        params: { id }
      }),
      providesTags: ["Police Case"]
    }),

    uploadDocument: builder.mutation<
      ApiTypes.UploadDocumentResponse,
      ApiTypes.UploadDocumentRequest
    >({
      query: ({ data }) => ({
        url: `/document/upload`,
        method: `POST`,
        body: data
      }),
      invalidatesTags: ["Document", "Police Case"]
    }),

    getAllDocumentTypes: builder.query<
      ApiTypes.GetAllDocumentTypesResponse,
      ApiTypes.GetAllDocumentTypesRequest
    >({
      query: () => ({
        url: `/document-type/list/get`,
        method: `GET`
      })
    }),

    getDocumentsFromCase: builder.query<
      ApiTypes.GetDocumentsFromCaseResponse,
      ApiTypes.GetDocumentsFromCaseRequest
    >({
      query: ({ policeCaseId, documentTypeName, patientId }) => ({
        url: `/document/list/get`,
        method: `GET`,
        params: { policeCaseId, documentTypeName, patientId }
      }),
      providesTags: ["Document"]
    }),

    getPatientsFromCase: builder.query<
      ApiTypes.GetPatientsFromCaseResponse,
      ApiTypes.GetPatientsFromCaseRequest
    >({
      query: ({ policeCaseId }) => ({
        url: `/patient/list/get`,
        method: `GET`,
        params: { policeCaseId }
      })
    }),

    useSearchPoliceCases: builder.query<
      ApiTypes.SearchCaseResponse,
      ApiTypes.SearchCaseRequest
    >({
      query: (data) => ({
        url: `/police-case/search/get`,
        method: `GET`,
        params: data
      })
    }),

    getAllCasesCount: builder.query<
      ApiTypes.GetAllCasesCountResponse,
      ApiTypes.GetAllCasesCountRequest
    >({
      query: () => ({
        url: `/patient/statistics`,
        method: `GET`
      })
    }),

    addPatientToCase: builder.mutation<
      ApiTypes.AddPatientToCaseResponse,
      ApiTypes.AddPatientToCaseRequest
    >({
      query: (data) => ({
        url: `/police-case/patient/add`,
        method: `POST`,
        body: data
      }),
      invalidatesTags: ["Police Case"]
    }),

    getPoliceCaseReport: builder.query<
      ApiTypes.GetPoliceCaseReportResponse,
      ApiTypes.GetPoliceCaseReportRequest
    >({
      query: ({ id }) => ({
        url: `/police-case/report/get`,
        method: `GET`,
        params: { id }
      }),
      providesTags: ["Police Case"]
    }),
    addPatientInformationToCaseProfile: builder.mutation<
      ApiTypes.AddPatientInformationToPoliceCaseProfileResponse,
      ApiTypes.AddPatientInformationToPoliceCaseProfileRequest
    >({
      query: (data) => ({
        url: `/document/information/update`,
        method: `POST`,
        body: data
      }),
      invalidatesTags: ["Document"]
    })
  }),
  overrideExisting: false
});

export const {
  useCreateCaseMutation,
  useGetAllCasesQuery,
  useLazyGetPoliceCaseDetailsQuery,
  useUploadDocumentMutation,
  useGetAllDocumentTypesQuery,
  useGetDocumentsFromCaseQuery,
  useLazyUseSearchPoliceCasesQuery,
  useGetPoliceCaseDetailsQuery,
  useAddPatientToCaseMutation,
  useGetAllCasesCountQuery,
  useGetPoliceCaseReportQuery,
  useLazyGetPoliceCaseReportQuery,
  useAddPatientInformationToCaseProfileMutation
} = policeCaseApi;
