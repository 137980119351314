import React from "react";
import { Tag } from "@chakra-ui/react";

interface Props {
  status: string;
}

export const RecordRequestStatusTag: React.FC<Props> = (props: Props) => {
  let colorScheme = "blue";
  if (props.status === "Pending") colorScheme = "yellow";
  else if (props.status === "Fulfilled") colorScheme = "green";
  else if (props.status === "Missing") colorScheme = "red";
  else if (props.status === "Denied") colorScheme = "orange";

  return <Tag colorScheme={colorScheme}>{props.status}</Tag>;
};
